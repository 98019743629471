import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-scroll';

function MenuHamburguesa(props) {



  return (
    <PopupState variant="popover" popupId="demo-popup-menu" classname="menu">
    {(popupState) => (
      <React.Fragment>
        <Button variant="contained" {...bindTrigger(popupState)}>
          <MenuIcon/>
        </Button>
        <Menu {...bindMenu(popupState)}>
          <MenuItem onClick={()=>{popupState.close();}}><Link activeClass="active" to="test1" spy={true} smooth={true} offset={50} duration={300} onClick={popupState.close}>Consultas</Link></MenuItem>
          {/* <MenuItem onClick={popupState.close}>My account</MenuItem>
          <MenuItem onClick={popupState.close}>Logout</MenuItem> */}
        </Menu>
      </React.Fragment>
    )}
  </PopupState>
  );
}

export default MenuHamburguesa;
