import '../App.css';
import { useField } from 'formik';
import { TextField } from '@mui/material';

const CampoDeTexto = ({
    name,
    ...otrosProps
}) => {

    const [campo, datos] = useField(name);

    const configDelCampo = {
        ...campo,
        ...otrosProps,
        variant: "outlined",
    };

    if (datos && datos.touched && datos.error) {
        configDelCampo.error = true;
        configDelCampo.helperText = datos.error;
    }

    return(
        <TextField className='campoTexto' margin="normal" {...configDelCampo}></TextField>
    );



};

export default CampoDeTexto;