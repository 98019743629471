import "../css/propiedad.css";


function Propiedad(props) {


  return (
    <div className="caja" >
        <a href={props.link}>
            <img src={props.imagen} className="imagen" href={props.link} />
            <p className="texto"> {props.nombre} </p>
        </a>
       

    </div>
  );
}

export default Propiedad;
