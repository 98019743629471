import logo from './logo.svg';
import './App.css';
import HomePage from './components/HomePage.js';
import { Helmet } from "react-helmet";

function App() {

  return (
    <>

      <Helmet>
        <title>Inmobiliaria Ana Clara Jiménez</title>
        <meta 
          name='description'
          content='Busca las propiedades que quieras'
        />
        <meta 
          name='keywords'
          content='Propiedades, Inmuebles, '
        />
      </Helmet>
      <div className="App">
        <HomePage/>
      </div>
    </>

  );
}

export default App;
