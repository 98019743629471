import "../css/homePage.css";
import "../css/form.css";
import Propiedad from "./Propiedad";
import argPropImg from "../utils/imagenes/argProps.jpg";
import zonaPropImg from "../utils/imagenes/zonaProps.jpg";
import mercadoLibrePropImg from "../utils/imagenes/mercadoLibreProps.jpg";
import CampoDeTexto from "./CampoDeTexto"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import * as Yup from "yup";
import { Formik, Form, } from 'formik';

import React, { useRef, useState } from 'react';

import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import MenuHamburguesa from "./MenuHamburguesa";
import InstagramIcon from '@mui/icons-material/Instagram';
import emailjs from '@emailjs/browser';


function HomePage() {

    const links = [
        {
          link: "https://www.argenprop.com/INMOBILIARIA-ACJ/inmuebles-anunciante-285929",
          imagen: argPropImg,
          nombre: "ARGEN PROP",
        },
        {
          link: "https://zonaprop.com.ar/web/acj",
          imagen: zonaPropImg,
          nombre: "ZONA PROP",
        },
        {
          link: "https://inmuebles.mercadolibre.com.ar/_CustId_1349362468",
          imagen: mercadoLibrePropImg,
          nombre: "MERCADO LIBRE",
        },
    ]

    const [seEnvioConsulta, setSeEnvioConsulta] = useState(false);
    const [consultaExitosa, setConsultaExitosa] = useState(true);


    const VALORES_INICIALES = {
        nombre: "",
        apellido: "",
        correo: "",
        celular: "",
        consulta: "",
      }
        
      const FORM_VALIDATION = Yup.object().shape({
        nombre: Yup.string()
            .matches(/^[a-zA-ZÀ-ÿ\s]{1,40}$/ , 'Ingrese un nombre válido')
            .required("Ingrese un nombre"),
        apellido: Yup.string()
            .matches(/^[a-zA-ZÀ-ÿ\s]{1,40}$/ , 'Ingrese un apellido válido')
            .required("Ingrese un apellido"),
        correo: Yup.string()
            .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/ , 'Ingrese un Email válido')
            .required("Ingrese un correo"),
        celular: Yup.string()
            .matches(/^[\[0-9\]*]{1,40}$/ , 'Ingrese un celular válido (sin "+" ni "-")')
            .required("Ingrese un celular"),
        consulta: Yup.string()
            .required("Ingrese su consulta"),
      });

      const formulario = useRef();

      const sendEmail = (acciones) => {
    
        emailjs.sendForm('service_5oec6hu', 'template_lka1oqe', formulario.current, 'aoK1_bXoAkdRbt1vV')
          .then((result) => {
            //   console.log(result.text);
            setSeEnvioConsulta(true);
            setConsultaExitosa(true)
            acciones.resetForm();
          }, (error) => {
            //   console.log(error.text);
            setSeEnvioConsulta(true);
            setConsultaExitosa(false)
          });
      };
    

  return (
    <motion.div
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.8 }} 
        className='main'>
        
        <div className="menu" >
            <MenuHamburguesa />
        </div>

                    
        <div className="titulo">
            <p style={{"textAlign": "left", "marginBottom": "10px"}}>
                <span style={{"color": "#22bfc5", "display": "block"}}>INMOBILIARIA</span>
                <span style={{"display": "block"}} className="legales">CMCPSI: 6988</span>
                <span style={{"display": "block", "marginTop": "-10px"}} className="legales">CUCICBA: 8766</span>
                <span style={{"display": "block", "marginTop": "-10px"}} className="legales"><span className="poco-importante"></span> Ituzaingó 200, San Isidro</span>
            </p>

            <p style={{"textAlign": "left", "marginBottom": "10px"}}>
                <span style={{"display": "block", "textAlign": "center"}} className="legales"> <InstagramIcon style={{"marginBottom": "2%"}}/> <span className="poco-importante"></span><a href="https://www.instagram.com/inmobiliaria.acj/" style={{"color": "#22bfc5", "text-decoration": "underline" }}>@inmobiliaria.acj</a></span>
                <span style={{"display": "block", "marginTop": "-5px", "textAlign": "center"}} className="legales"><WhatsAppIcon style={{"color": "#03c100", "marginBottom": "2%"}}/> <a href="https://wa.link/rgn0e6" style={{"color": "#22bfc5", "text-decoration": "underline" }}> <span className="poco-importante"></span>11-3392-2222</a> </span>
                <span style={{"display": "block", "textAlign": "center", "fontSize":"small"}} className="legales"> VENTAS / TASACIONES / ALQUILER ANUAL Y TEMPORAL</span>
            </p>

            <p > 
                Ana<b className="resaltado">Clara</b>
                <span style={{"display": "block", "marginTop": "-15px"}}>Jiménez</span> 
                Las cosas <b className="resaltado">claras</b> 
            </p>

            <p style={{"textAlign": "left", "marginBottom": "10px", "width":"100%"}}>
                <span style={{"display": "block", "textAlign": "center", "fontSize":"small"}} className="legales">ADMINISTRACIÓN DE CONSORCIOS / ADMINISTRACION DE ALQUILERES</span>
            </p>
        </div>

        <div className="cartel-props">
            <h1>NUESTRAS PROPIEDADES</h1>
        </div>

        <div style={{"marginTop": "90px", "marginBottom": "90px"}}>
            <div className="link-container">
                {links.map(link => 
                    <Propiedad link={link.link} imagen={link.imagen} nombre={link.nombre} key={link.link} />
                )}
            </div>
        </div>

        <div className="consultas" id="consultas" name="test1">
            <Formik
                initialValues={VALORES_INICIALES}
                validationSchema={FORM_VALIDATION}
                
                onSubmit={(values, actions) => {sendEmail(actions)}}>
                {( { errors, }) => (
                    <Form className='formulario' ref={formulario}>
                        <p style={{"textAlign": "center"}}><b>CONSULTAS Y TASACIONES</b></p>

                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: "flex-start", width: "100%", textAlign: "start" }}>
                        <div style={{"width": "100%"}}>
                            <CampoDeTexto 
                            type="text" 
                            id="nombre" 
                            name='nombre' 
                            placeholder='Juan' 
                            label="Nombre"
                            sx={{ width: 0.4, mr: 3 }}

                            />
                        
                            <CampoDeTexto 
                            type="text" 
                            id="apellido" 
                            name='apellido' 
                            placeholder='Perez' 
                            label="Apellido"
                            sx={{ width: 0.4,}}
                            />
                        </div>
                        <div style={{"width": "100%"}}>
                            <CampoDeTexto 
                            type="text" 
                            id="correo" 
                            name='correo' 
                            placeholder='ejemplo@ejemplo.com'
                            label="Email"
                            sx={{ width: 0.4, mr: 3 }}
                            />
                            <CampoDeTexto 
                            type="text" 
                            id="celular" 
                            name='celular' 
                            placeholder='1194683572'
                            label="Celular"
                            sx={{ width: 0.4}}
                            />
                        </div>
                        <div style={{"width": "100%"}}>
                            <CampoDeTexto 
                            type="text" 
                            id="consulta" 
                            name='consulta' 
                            placeholder='1194683572'
                            label="Consulta"
                            sx={{ width: 0.65 }}
                            multiline
                            rows={4}
                            />
                        </div>
                        </Box>
                        <Button type='submit' variant="contained">Enviar</Button>
                   
                    </Form>
                )}
                </Formik>
                {seEnvioConsulta && <div>
                    {consultaExitosa && <p style={{"color": "green"}}>Se envio la consulta</p>}
                    {!consultaExitosa && <p style={{"color": "red"}}>Hubo un error al enviar la consulta, intentar nuevamente</p>}
                </div>}
        </div>
        
        {/* <Accordion expanded={expaxdir} style={{"marginBottom": "90px"}} className="consultas">
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography><b>Consultas y tasaciones</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Formik
                initialValues={VALORES_INICIALES}
                validationSchema={FORM_VALIDATION}
                
                onSubmit={async(valores) =>{
                    
                }}>
                {( { errors, }) => (
                    <Form className='formulario'>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: "flex-start", width: "100%", textAlign: "start" }}>
                        <div style={{"width": "100%"}}>
                            <CampoDeTexto 
                            type="text" 
                            id="nombre" 
                            name='nombre' 
                            placeholder='Juan' 
                            label="Nombre"
                            sx={{ width: 0.4, mr: 3 }}

                            />
                        
                            <CampoDeTexto 
                            type="text" 
                            id="apellido" 
                            name='apellido' 
                            placeholder='Perez' 
                            label="Apellido"
                            sx={{ width: 0.4,}}
                            />
                        </div>
                        <div style={{"width": "100%"}}>
                            <CampoDeTexto 
                            type="text" 
                            id="correo" 
                            name='correo' 
                            placeholder='ejemplo@ejemplo.com'
                            label="Email"
                            sx={{ width: 0.4, mr: 3 }}
                            />
                            <CampoDeTexto 
                            type="text" 
                            id="celular" 
                            name='celular' 
                            placeholder='1194683572'
                            label="Celular"
                            sx={{ width: 0.4}}
                            />
                        </div>
                        <div style={{"width": "100%"}}>
                            <CampoDeTexto 
                            type="text" 
                            id="consulta" 
                            name='consulta' 
                            placeholder='1194683572'
                            label="Consulta"
                            sx={{ width: 0.65 }}
                            multiline
                            rows={4}
                            />
                        </div>
                        </Box>
                        <Button type='submit' variant="contained">Enviar</Button>
                   
                    </Form>
                )}
                </Formik>
            </AccordionDetails>
        </Accordion> */}
    
    {/* <Footer/> */}
    </motion.div>
  );
}

export default HomePage;
